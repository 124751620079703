import React from "react";
import Menu from "../common/Menu";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const SocialWebMobile = ({ data }) => {
  return (
    <div className="social_web_mobile social_web_bg">
      <div className="container">
        <div className="row">
          <div className="col-sm-3 col-3">
            <Link to="/">
              <GatsbyImage
                image={data.back.asset.gatsbyImageData}
                alt={data.back.asset.altText || "Back"}
                className="img-fluid social_web_mobile_tatoo_1"
                loading="eager"
                placeholder="blurred"
              />
            </Link>
          </div>
          <div className="col-sm-6 col-6">
            <center>
              <Link to="/">
                <GatsbyImage
                  image={data.logo.asset.gatsbyImageData}
                  alt={data.logo.asset.altText || "Social Web Logo"}
                  className="img-fluid social_web_mobile_tatoo_2"
                  loading="eager"
                  placeholder="blurred"
                />
              </Link>
            </center>
          </div>
          <div className="col-sm-3 col-3">
            {/*Burger Menu Start-*/}
            <Menu />
            {/*Burger Menu End-*/}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-6">
            <a href={data.twitter.socialUrl} target="_blank">
              <GatsbyImage
                image={data.twitter.socialImage.asset.gatsbyImageData}
                alt={data.twitter.socialImage.asset.altText || "Twitter"}
                className="img-fluid social_web_mobile_tatoo_4"
                loading="eager"
                placeholder="blurred"
              />
            </a>
          </div>
          <div className="col-sm-6 col-6">
            <a href={data.instagram.socialUrl} target="_blank">
              <GatsbyImage
                image={data.instagram.socialImage.asset.gatsbyImageData}
                alt={data.instagram.socialImage.asset.altText || "Instagram"}
                className="img-fluid social_web_mobile_tatoo_5"
                loading="eager"
                placeholder="blurred"
              />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-6">
            <a href={data.youtube.socialUrl} target="_blank">
              <GatsbyImage
                image={data.youtube.socialImage.asset.gatsbyImageData}
                alt={data.youtube.socialImage.asset.altText || "Youtube"}
                className="img-fluid social_web_mobile_tatoo_6"
                loading="eager"
                placeholder="blurred"
              />
            </a>
          </div>
          <div className="col-sm-6 col-6">
            <Link to={data.puppyUrl.current}>
              <GatsbyImage
                image={data.puppyImage.asset.gatsbyImageData}
                alt={data.puppyImage.asset.altText || "PuppyGram"}
                className="img-fluid social_web_mobile_tatoo_7"
                loading="eager"
                placeholder="blurred"
              />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-12">
            <center>
              <a href={data.good.socialUrl} target="_blank">
                <GatsbyImage
                  image={data.good.socialImage.asset.gatsbyImageData}
                  alt={data.good.socialImage.asset.altText || "PuppyGram"}
                  className="img-fluid social_web_mobile_tatoo_8"
                  loading="eager"
                  placeholder="blurred"
                />
              </a>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialWebMobile;
