import React from "react";
import SocialWebDesktop from "../components/socialweb/Desktop";
import useDimensions from "../hooks/useDimensions";
import SocialWebMobile from "../components/socialweb/Mobile";
import "../css/social_web.css";
import { graphql } from "gatsby";

const SocialWeb = ({ data }) => {
  const [width] = useDimensions();
  const socialWeb = data.sanityPage.socialWebTemp;
  const seo = data.sanityPage.seo;

  return (
    <div>
      {width &&
        (width > 768 ? (
          <SocialWebDesktop data={socialWeb} />
        ) : (
          <SocialWebMobile data={socialWeb} />
        ))}
    </div>
  );
};

export const query = graphql`
  query SociaWebTemp($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      seo {
        title
        pagehandle
        ldSchema
        keywords
        description
      }
      title
      template
      slug {
        current
      }
      socialWebTemp {
        back {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        logo {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        twitter {
          socialUrl
          socialImage {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
        }
        instagram {
          socialUrl
          socialImage {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
        }
        youtube {
          socialUrl
          socialImage {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
        }
        puppyImage {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        puppyUrl {
          current
        }
        good {
          socialUrl
          socialImage {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
        }
      }
    }
  }
`;
export default SocialWeb;
