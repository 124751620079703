import React, { useState, Fragment } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import hamBurger from "../../assets/hamburger.png";
const Menu = () => {
  const { allSanityMenu } = useStaticQuery(graphql`
    query {
      allSanityMenu {
        nodes {
          id
          title
          slug
          image {
            asset {
              gatsbyImageData(placeholder: DOMINANT_COLOR, formats: WEBP)
              altText
            }
          }
        }
      }
    }
  `);
  const [openMenu, setOpenMenu] = useState(false);
  function handleClick(e) {
    e.preventDefault();
    setOpenMenu((prevState) => !prevState);
  }

  return (
    <Fragment>
      {/*Burger Menu Start-*/}
      <div
        className={`button_container movie_tatto4 ${openMenu ? "active" : ""}`}
        id="toggle"
        onClick={handleClick}
      >
        <img
          src={hamBurger}
          className="img-fluid top hamburger_menu_btn"
          alt="hamburger menu"
          loading="eager"
        />
        <br />
        <img
          src={hamBurger}
          className="img-fluid middle hamburger_menu_btn"
          alt="hamburger menu"
          loading="eager"
        />
        <br />
        <img
          src={hamBurger}
          className="img-fluid bottom hamburger_menu_btn"
          alt="hamburger menu"
          loading="eager"
        />
      </div>
      <div
        className={`menu_overlay ${openMenu ? "open" : ""}`}
        id="overlay"
        onClick={handleClick}
      >
        <nav className="overlay-menu">
          <ul>
            {allSanityMenu.nodes.map((item) => (
              <li key={item.id}>
                <Link to={item.slug}>
                  <GatsbyImage
                    image={item.image.asset.gatsbyImageData}
                    alt={item.image.asset.altText || item.title}
                    loading="lazy"
                  />
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      {/*Burger Menu End-*/}
    </Fragment>
  );
};

export default Menu;
