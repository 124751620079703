import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Menu from "../common/Menu";

const SocialWebDesktop = ({ data }) => {
  return (
    <div className="social_web social_web_bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 col-3">
            <Link to="/">
              <GatsbyImage
                image={data.back.asset.gatsbyImageData}
                alt={data.back.asset.altText || "Back"}
                className="img-fluid social_web_tatoo_1"
                loading="eager"
                objectFit="contain"
              />
              
            </Link>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
            <center>
              <Link to="/">
                <GatsbyImage
                  image={data.logo.asset.gatsbyImageData}
                  alt={data.logo.asset.altText || "Social Web Logo"}
                  className="img-fluid social_web_tatoo_2"
                  loading="eager"
                  objectFit="contain"
                />
              </Link>
            </center>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 col-3">
            {/*Burger Menu Start-*/}
            <Menu />
            {/*Burger Menu End-*/}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-md-5">
            <a href={data.twitter.socialUrl} target="_blank">
              <GatsbyImage
                image={data.twitter.socialImage.asset.gatsbyImageData}
                alt={data.twitter.socialImage.asset.altText || "Twitter"}
                className="img-fluid social_web_tatoo_4"
                loading="eager"
                objectFit="contain"
              />
            </a>
          </div>
          <div className="col-lg-3 col-md-3"></div>
          <div className="col-lg-4 col-md-4">
            <a href={data.instagram.socialUrl} target="_blank">
              <GatsbyImage
                image={data.instagram.socialImage.asset.gatsbyImageData}
                alt={data.instagram.socialImage.asset.altText || "Instagram"}
                className="img-fluid social_web_tatoo_5"
                loading="eager"
                objectFit="contain"
              />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <a href={data.youtube.socialUrl} target="_blank">
              <GatsbyImage
                image={data.youtube.socialImage.asset.gatsbyImageData}
                alt={data.youtube.socialImage.asset.altText || "Youtube"}
                className="img-fluid social_web_tatoo_6"
                loading="eager"
                objectFit="contain"
              />
            </a>
          </div>
          <div className="col-lg-4 col-md-4"></div>
          <div className="col-lg-4 col-md-4">
            {/*<a title="" href="#" data-toggle="modal" data-target="#lightbox"><StaticImage alt="" src="https://www.matthewgraygubler.com/images/social-web-img/puppygram.png" class="img-fluid social_web_tatoo_7"></a>*/}
            <Link to={data.puppyUrl.current}>
              <GatsbyImage
                image={data.puppyImage.asset.gatsbyImageData}
                alt={data.puppyImage.asset.altText || "PuppyGram"}
                className="img-fluid social_web_tatoo_7"
                loading="eager"
                objectFit="contain"
              />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <center>
              <a href={data.good.socialUrl} target="_blank">
                <GatsbyImage
                  image={data.good.socialImage.asset.gatsbyImageData}
                  alt={data.good.socialImage.asset.altText || "PuppyGram"}
                  className="img-fluid social_web_tatoo_8"
                  loading="eager"
                  objectFit="contain"
                />
              </a>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialWebDesktop;
